/** @jsxImportSource @emotion/react */
import {
  Button,
  createStyles,
  Input,
  List,
  ListItem,
  Typography,
  Select,
  useTheme,
  DateInput,
  useForm,
  ToggleButtonGroup,
  UtilityButton,
} from '@quickbit/qb-design-system';
import { useCallback, useState } from 'react';
import {
  useAsync,
  useAsyncFn,
  useLocalStorage,
  useSessionStorage,
  useUpdateEffect,
} from 'react-use';
import { Layout } from 'components';
import { Order } from 'types';
import { encryptCardData } from 'utils/encryptCardData';
import {
  createOrder,
  getOrder,
  getMerchantApiKey,
  getPlatformApiKey,
  getPlatform,
  addNewPendingMerchant,
  CardData,
  CreateOrderCardData,
  Address,
} from '../api';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ObjectProperties = ({ object }: { object: Record<string, any> }) => (
  <>
    {Object.entries(object).map(([k, v]) => {
      if (typeof v === 'object' && v !== null && !Array.isArray(v)) {
        const nestedV: Record<string, string> = v;
        return (
          <div key={k}>
            <List>
              {Object.entries(nestedV).map(([k2, v2]) => (
                <ListItem key={k2} title={k} subtitle={k2} subcontent={v2} />
              ))}
            </List>
          </div>
        );
      }
      const actualV = Array.isArray(v) ? JSON.stringify(v, undefined, 1) : v;
      return (
        <ListItem
          key={k}
          title={k}
          subcontent={
            <pre
              css={{
                overflowX: 'scroll',
                maxWidth: '500px',
                '::-webkit-scrollbar': { display: 'none' },
                marginLeft: '4px',
              }}
            >{`${actualV}`}</pre>
          }
        />
      );
    })}
  </>
);

const notEmpty = (v: string) => (v === '' ? 'Cannot be empty' : undefined);

export const MockMerchantOrPlatform = () => {
  const { spacing } = useTheme();
  const styles = useStyles();
  const [formValues, setFormValues] = useState({
    id: '',
    apiKey: '',
  });
  const [orderType, setOrderType] = useState('topup');
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const [apiKey, setApiKey] = useLocalStorage<string | null>('apiKey', null);
  const [selectedPlatformMerchant, setSelectedPlatformMerchant] =
    useState<string>();
  const [newOrderAmount, setNewOrderAmount] = useState(25);
  const [newOrderCurrency, setNewOrderCurrency] = useState('SEK');
  const [referenceId, setReferenceId] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [failureRedirectUrl, setFailureRedirectUrl] = useState('');
  const [refundRedirectUrl, setRefundRedirectUrl] = useState('');
  const [searchOrderId, setSearchOrderId] = useState('');
  const [countryCode, setCountryCode] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [dob, setDob] = useState<Date>();
  const [order, setOrder] = useSessionStorage<Order | null | undefined>(
    'order',
    null
  );
  const [encryptedData, setEncryptedData] = useSessionStorage<{
    cardNumber: string;
    cvv: string;
  } | null>('order_encryptedData', null);

  const { setters, fields, hasErrors } = useForm<CardData>(
    {
      cardHolder: '',
      creditCardNumber: '',
      cvv: '',
      expiryMonth: '12',
      expiryYear: '2030',
    },
    {
      cardHolder: notEmpty,
      creditCardNumber: notEmpty,
      cvv: notEmpty,
      expiryMonth: notEmpty,
      expiryYear: notEmpty,
    }
  );

  const {
    setters: addressSetters,
    fields: addressFields,
    hasErrors: addressHasErrors,
  } = useForm<Address>(
    {
      street: 'Hemma 3',
      zip: '',
      countryCode: 'SE',
      city: 'Stockholm',
    },
    {
      street: notEmpty,
      zip: notEmpty,
      countryCode: notEmpty,
      city: notEmpty,
    }
  );

  const clearApiKeyLocalStorage = useCallback(() => {
    setFormValues({ id: '', apiKey: '' });
    setApiKey(null);
    setOrder(null);
    setEncryptedData(null);
  }, [setApiKey, setOrder, setEncryptedData]);

  const clearOrderSessionStorage = useCallback(() => {
    setOrder(null);
    setEncryptedData(null);
  }, [setOrder, setEncryptedData]);

  const [{ loading: fetchApiKeyLoading }, fetchMerchantOrPlatformApiKey] =
    useAsyncFn(async (merchantOrPlatformId: string) => {
      try {
        const res = await getMerchantApiKey(merchantOrPlatformId);
        setApiKey(res.apiKey);
        return;
      } catch {
        //
      }
      try {
        const res = await getPlatformApiKey(merchantOrPlatformId);
        setApiKey(res.apiKey);
        return;
      } catch {
        //
      }
    });

  const { value: platform } = useAsync(async () => {
    if (!apiKey) return;
    return getPlatform(apiKey);
  }, [apiKey]);

  const [, addNewMerchant] = useAsyncFn(async () => {
    if (!apiKey) return;
    const response = await addNewPendingMerchant(apiKey);
    window.open(response.signupFormUrl);
    return response;
  }, [apiKey]);

  const [{ value: newOrder, loading: orderCreateLoading }, createNewOrder] =
    useAsyncFn(
      async (useMigrationFlow: boolean, useProcessingFlow: boolean) => {
        if (!apiKey) return null;
        let creditCardData: CreateOrderCardData | undefined = undefined;
        if (!hasErrors) {
          const [encCard, encCvv] = await encryptCardData(apiKey, fields);
          setEncryptedData({ cardNumber: encCard, cvv: encCvv });
          creditCardData = {
            ...fields,
            encryptedCreditCardNumber: encCard,
            encryptedCvv: encCvv,
          } as CreateOrderCardData;
        }
        let userData = undefined;
        if (firstName && lastName && dob && countryCode && email) {
          userData = {
            user: {
              firstName,
              lastName,
              birthdate: dob,
              countryCode,
              email,
            },
          };
        }
        if (!addressHasErrors) {
          userData = {
            ...userData,
            homeAddress: addressFields,
          };
        }
        return createOrder({
          amount: newOrderAmount,
          currency: newOrderCurrency,
          apiKey: apiKey,
          useMigrationFlow,
          useProcessingFlow,
          referenceId,
          redirectUrl,
          failureRedirectUrl,
          refundRedirectUrl,
          merchantId: selectedPlatformMerchant,
          countryCode,
          email,
          creditCardData,
          userData,
        });
      },
      [
        newOrderAmount,
        newOrderCurrency,
        apiKey,
        referenceId,
        redirectUrl,
        failureRedirectUrl,
        refundRedirectUrl,
        selectedPlatformMerchant,
        countryCode,
        email,
        hasErrors,
        fields,
        firstName,
        lastName,
        dob,
      ]
    );

  const [{ value: searchOrder, loading: orderSearchLoading }, findOrder] =
    useAsyncFn(
      async (orderIdToFind) =>
        apiKey ? getOrder(orderIdToFind, apiKey) : undefined,
      [apiKey]
    );

  useUpdateEffect(() => {
    setOrder(newOrder);
  }, [setOrder, newOrder]);

  useUpdateEffect(() => {
    setOrder(searchOrder);
  }, [setOrder, searchOrder]);

  const checkoutUrl = order ? order.checkoutUrl : undefined;

  return (
    <Layout title={`Mock ${platform ? 'Platform' : 'Merchant'}`}>
      <div css={styles.container}>
        {!apiKey && (
          <form css={styles.merchantForm}>
            <fieldset>
              <legend>Get merchant or platform API key</legend>
              <Input
                label="Merchant or Platform ID"
                value={formValues.id}
                onChange={(e) => {
                  setFormValues((prevState) => ({
                    ...prevState,
                    id: e.target.value,
                  }));
                }}
              />
              <Button
                variant="primary"
                text="Get merchant"
                onClick={() => fetchMerchantOrPlatformApiKey(formValues.id)}
                disabled={!formValues.id || fetchApiKeyLoading}
                loading={fetchApiKeyLoading}
              />
            </fieldset>
            <fieldset>
              <legend>Use merchant API key</legend>
              <Input
                label="Merchant API key"
                value={formValues.apiKey}
                onChange={(e) => {
                  setFormValues((prevState) => ({
                    ...prevState,
                    apiKey: e.target.value,
                  }));
                }}
              />
              <Button
                variant="secondary"
                text="Continue"
                onClick={() => setApiKey(formValues.apiKey)}
                disabled={!formValues.apiKey || formValues.apiKey.length < 64}
              />
            </fieldset>
          </form>
        )}
        {apiKey && (
          <div css={{ marginTop: spacing.s }}>
            {!order && (
              <>
                <ToggleButtonGroup
                  selected={orderType}
                  onChange={(o) => {
                    setOrderType(o);
                    if (o === 'processing') {
                      setNewOrderCurrency('EUR');
                    }
                  }}
                  toggleOptions={[
                    { label: 'Pay', value: 'pay' },
                    { label: 'TopUp', value: 'topup' },
                    { label: 'Processing', value: 'processing' },
                    { label: 'Find Order', value: 'findOrder' },
                  ]}
                />
                {orderType === 'findOrder' && (
                  <div css={styles.orderField}>
                    <Input
                      label="Order ID"
                      value={searchOrderId}
                      onChange={(e) => setSearchOrderId(e.target.value)}
                    />
                    <Button
                      variant="primary"
                      text="Find order"
                      loading={orderSearchLoading}
                      onClick={() => findOrder(searchOrderId)}
                      disabled={searchOrderId === ''}
                    />
                  </div>
                )}
                {orderType !== 'findOrder' && (
                  <>
                    {' '}
                    <div css={styles.orderField}>
                      <Input
                        label="Order amount"
                        value={newOrderAmount.toString()}
                        onChange={(e) =>
                          setNewOrderAmount(Number(e.target.value))
                        }
                        type="number"
                      />
                      <Select
                        label="Order currency"
                        values={[newOrderCurrency]}
                        options={[
                          { label: 'SEK', value: 'SEK' },
                          { label: 'NOK', value: 'NOK' },
                          { label: 'EUR', value: 'EUR' },
                          { label: 'USD', value: 'USD' },
                        ]}
                        onSelect={(e) => setNewOrderCurrency(e[0])}
                      />
                    </div>
                    {!showOptionalFields && (
                      <div css={{ display: 'flex', justifyContent: 'center' }}>
                        <UtilityButton
                          text="Show optional generic fields"
                          onClick={() => setShowOptionalFields(true)}
                          variant="secondary"
                          css={{
                            marginBottom:
                              orderType === 'pay' ? spacing.s : 'unset',
                            alignSelf: 'center',
                          }}
                        />
                      </div>
                    )}
                    {showOptionalFields && (
                      <>
                        <div css={styles.orderField}>
                          <Input
                            label="Redirect URL"
                            value={redirectUrl}
                            onChange={(e) => setRedirectUrl(e.target.value)}
                          />
                          <Input
                            label="Failure redirect URL"
                            value={failureRedirectUrl}
                            onChange={(e) =>
                              setFailureRedirectUrl(e.target.value)
                            }
                          />
                          <Input
                            label="Refund redirect URL"
                            value={refundRedirectUrl}
                            onChange={(e) =>
                              setRefundRedirectUrl(e.target.value)
                            }
                          />
                        </div>
                        <div css={styles.orderField}>
                          <Input
                            label="Reference ID"
                            value={referenceId}
                            onChange={(e) => setReferenceId(e.target.value)}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
                {['topup', 'processing'].includes(orderType) && (
                  <>
                    <div css={styles.orderField}>
                      <Input
                        label="Email"
                        value={email ?? ''}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </>
                )}
                {orderType === 'topup' && (
                  <>
                    <div css={styles.orderField}>
                      <Select
                        label="Country"
                        values={countryCode ? [countryCode] : undefined}
                        options={[
                          { label: 'Sweden', value: 'SE' },
                          { label: 'Norway', value: 'NO' },
                          { label: 'Onfido(UK)', value: 'GB' },
                        ]}
                        onSelect={(e) => setCountryCode(e[0])}
                      />
                    </div>
                    <div css={styles.orderField}>
                      <Input
                        label="First name"
                        value={firstName ?? ''}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <Input
                        label="Last name"
                        value={lastName ?? ''}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                    <div
                      css={{
                        marginBottom: spacing.s,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <DateInput
                        placeholder="Date of birth"
                        value={dob}
                        type="default"
                        onChange={(e: Date) => {
                          setDob(e);
                        }}
                      />
                      <div>
                        {firstName &&
                          lastName &&
                          dob &&
                          countryCode &&
                          email &&
                          'UserData OK ✅'}
                      </div>
                    </div>
                    <div css={{ display: 'flex', justifyContent: 'center' }}>
                      -- Save address for user --{' '}
                      {!addressHasErrors && 'OK 👍✅'}
                    </div>
                    <div css={styles.orderField}>
                      <Input
                        label="Street"
                        value={addressFields.street}
                        onChange={(e) =>
                          addressSetters.street.set(e.target.value)
                        }
                      />
                      <Input
                        label="zip"
                        value={addressFields.zip}
                        onChange={(e) => addressSetters.zip.set(e.target.value)}
                      />
                      <Input
                        label="city"
                        value={addressFields.city}
                        onChange={(e) =>
                          addressSetters.city.set(e.target.value)
                        }
                      />
                      <Input
                        label="CountryCode"
                        value={addressFields.countryCode}
                        onChange={(e) =>
                          addressSetters.countryCode.set(e.target.value)
                        }
                      />
                    </div>
                    <div css={{ display: 'flex', justifyContent: 'center' }}>
                      -- Use FrontApi to pay -- {!hasErrors && 'OK 👍✅'}
                    </div>
                    <div css={styles.orderField}>
                      <Input
                        label="Card holder"
                        value={fields.cardHolder}
                        onChange={(e) => setters.cardHolder.set(e.target.value)}
                      />
                      <Input
                        label="Creditcard number"
                        value={fields.creditCardNumber}
                        onChange={(e) =>
                          setters.creditCardNumber.set(e.target.value)
                        }
                      />
                      <Input
                        label="CVV"
                        value={fields.cvv}
                        onChange={(e) => setters.cvv.set(e.target.value)}
                      />
                    </div>
                    <div css={styles.orderField}>
                      <Input
                        label="Expiry month"
                        value={fields.expiryMonth}
                        onChange={(e) =>
                          setters.expiryMonth.set(e.target.value)
                        }
                        helperText='Needs to be 2 digits, eg. "02"'
                      />
                      <Input
                        label="Expiry year"
                        value={fields.expiryYear}
                        onChange={(e) => setters.expiryYear.set(e.target.value)}
                        helperText='Needs to be 4 digits, eg. "2022"'
                      />
                    </div>
                  </>
                )}
                {orderType !== 'findOrder' && (
                  <div css={{ display: 'flex', gap: spacing.xxs }}>
                    <Button
                      variant="primary"
                      text={`Create ${orderType} order`}
                      loading={orderCreateLoading}
                      onClick={() =>
                        createNewOrder(
                          orderType === 'topup',
                          orderType === 'processing'
                        )
                      }
                      disabled={
                        orderType === 'processing' &&
                        (newOrderCurrency !== 'EUR' || !email)
                      }
                    />
                  </div>
                )}
                {platform && (
                  <div
                    css={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: spacing.xxs,
                    }}
                  >
                    <Select
                      values={
                        selectedPlatformMerchant
                          ? [selectedPlatformMerchant]
                          : undefined
                      }
                      label="Platform Merchants"
                      onSelect={(s) => setSelectedPlatformMerchant(s[0])}
                      options={platform.merchants.map((m) => ({
                        label: m.name,
                        value: m.merchantId,
                      }))}
                    />
                    <Button
                      text="Add new pending merchant"
                      onClick={() => addNewMerchant()}
                    />
                  </div>
                )}
              </>
            )}
            {order && (
              <div>
                <Typography variant="subtitle">Order</Typography>
                <List>
                  <ObjectProperties object={order} />
                  {encryptedData && (
                    <>
                      <ListItem
                        title="Encrypted Card Number"
                        subcontent={
                          <p css={{ lineBreak: 'anywhere' }}>
                            {encryptedData.cardNumber}
                          </p>
                        }
                      />
                      <ListItem
                        title="Encrypted CVV"
                        subcontent={
                          <p css={{ lineBreak: 'anywhere' }}>
                            {encryptedData.cvv}
                          </p>
                        }
                      />
                    </>
                  )}
                </List>
                <div css={{ marginTop: spacing.s }}>
                  <Button
                    variant="secondary"
                    text="Refresh order"
                    onClick={() => findOrder(order.uuid)}
                  />
                </div>
                {checkoutUrl && (
                  <div css={{ marginTop: spacing.s }}>
                    {orderType === 'pay' && (
                      <Button
                        variant="primary"
                        text="Open checkout in new window"
                        onClick={() => {
                          window.open(checkoutUrl);
                        }}
                      />
                    )}
                    <div css={{ marginTop: spacing.s }}>
                      <Typography variant="subtitle">
                        Checkout in iframe
                      </Typography>
                    </div>
                    <iframe
                      src={checkoutUrl}
                      allow="clipboard-write"
                      width={'100%'}
                      css={{ height: `${orderType === 'pay' ? '80vh' : ''}` }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div css={styles.actions}>
          <Button
            variant="tertiary"
            text="Clear API Key from localstorage"
            onClick={clearApiKeyLocalStorage}
          />
          <Button
            variant="tertiary"
            text="Clear Order"
            onClick={clearOrderSessionStorage}
          />
        </div>
      </div>
    </Layout>
  );
};

const useStyles = createStyles(({ palette, spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  merchantForm: {
    width: '100%',
    padding: spacing.s,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacing.xs,

    fieldset: {
      width: '100%',
      border: `2px solid ${palette.grey.x200}`,
      '> button': {
        marginBlock: spacing.xxs,
      },
      '> legend': {
        color: palette.grey.x600,
      },
    },
  },

  orderField: {
    display: 'flex',
    gap: spacing.s,
    marginBlock: spacing.s,
    button: {
      maxWidth: '30%',
    },
  },
  actions: {
    marginTop: spacing.l,
    display: 'flex',
    gap: spacing.s,
  },
}));
