import { fetchAPI } from 'api';
import { Order } from 'types';

export const getMerchantApiKey = (merchantId: string) => {
  return fetchAPI<{ apiKey: 'string' }>(
    `qb-admin/v1/merchant/${merchantId}/api-key`,
    {
      method: 'GET',
    }
  );
};

export const getPlatformApiKey = (platformId: string) => {
  return fetchAPI<{ apiKey: 'string' }>(
    `platforms/qb-admin/v1/platforms/${platformId}/api-key`,
    {
      method: 'GET',
    }
  );
};

interface PlatformMerchant {
  merchantId: string;
  name: string;
}

interface PendingMerchant {
  merchantId: string;
}

interface Platform {
  merchants: PlatformMerchant[];
  pending_merchants: PendingMerchant[];
}

export const getPlatform = (apiKey: string) => {
  return fetchAPI<Platform>(`platforms/v1/merchants`, {
    method: 'GET',
    headers: {
      'x-api-key': apiKey,
    },
  });
};

export const addNewPendingMerchant = (apiKey: string) => {
  return fetchAPI<{ merchantId: string; signupFormUrl: string }>(
    `platforms/v1/merchants`,
    {
      method: 'POST',
      headers: {
        'x-api-key': apiKey,
      },
    }
  );
};

export interface CreateOrderCardData {
  cardHolder: string;
  encryptedCreditCardNumber: string;
  encryptedCvv: string;
  expiryMonth: string;
  expiryYear: string;
}

export interface UserInfo {
  firstName: string;
  lastName: string;
  birthdate: Date;
  countryCode: string;
  email: string;
}

export interface Address {
  street: string;
  city: string;
  zip: string;
  countryCode: string;
}
export interface UserData {
  user?: UserInfo;
  homeAddress?: Address;
}

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

export const createOrder = ({
  amount,
  currency,
  apiKey,
  useMigrationFlow,
  useProcessingFlow,
  referenceId,
  redirectUrl,
  failureRedirectUrl,
  refundRedirectUrl,
  merchantId,
  countryCode,
  email,
  creditCardData,
  userData,
}: {
  amount: number;
  currency: string;
  apiKey: string;
  useMigrationFlow?: boolean;
  useProcessingFlow?: boolean;
  referenceId?: string;
  redirectUrl?: string;
  failureRedirectUrl?: string;
  refundRedirectUrl?: string;
  merchantId?: string;
  countryCode?: string;
  email?: string;
  creditCardData?: CreateOrderCardData;
  userData?: UserData;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const body: any = {
    amount,
    currency,
    callbackUrl: 'http://localhost:3000/api/quickbit-callback',
    referenceId,
    merchantId,
    useMigrationFlow,
    useProcessingFlow,
    countryCode,
    email,
    creditCardData,
    userData:
      userData && userData.user
        ? {
            ...userData,
            user: {
              ...userData.user,
              birthdate: formatDate(userData.user.birthdate),
            },
          }
        : userData,
  };
  if (redirectUrl) {
    body['redirectUrl'] = redirectUrl;
  }
  if (failureRedirectUrl) {
    body['failureRedirectUrl'] = failureRedirectUrl;
  }
  if (refundRedirectUrl) {
    body['refundRedirectUrl'] = refundRedirectUrl;
  }

  return fetchAPI<Order>('pay/v1/order', {
    method: 'POST',
    headers: {
      'x-api-key': apiKey,
    },
    body: body,
  });
};

export const getOrder = (orderId: string, apiKey: string) => {
  return fetchAPI<Order>(`pay/v1/order/${orderId}`, {
    method: 'GET',
    headers: {
      'x-api-key': apiKey,
    },
  });
};

export interface CardData {
  cardHolder: string;
  creditCardNumber: string;
  cvv: string;
  expiryMonth: string;
  expiryYear: string;
}

export interface PublicKeyResponse {
  publicKey: string;
}

export const getPublicKey = async (apiKey: string) => {
  return fetchAPI<PublicKeyResponse>(`pay/v1/encryption`, {
    method: 'GET',
    headers: {
      'x-api-key': apiKey,
    },
  });
};
